<template>
    <v-menu
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field readonly :placeholder="config.placeholder" :rules="config.rules" :required="config.required" @click:clear="model[config.key] = null" :dense="config.dense" :label="config.label" type="text" :value="formatDate(model[config.key])" v-bind="attrs"
                          v-on="on" clearable></v-text-field>
        </template>
        <v-date-picker :min="config.min" :max="config.max" :rules="config.rules" :required="config.required" v-model="model[config.key]"
                       ></v-date-picker>
    </v-menu>

</template>

<script>

    export default {
        name: "FormFieldDatePicker",
        props: [
            'model',
            'config'
        ],
        methods: {
            formatDate(date) {
                if (date) {
                    if (this.config.formatDate) {
                        return this.config.formatDate(date);
                    }
                    return date.split("-").reverse().join("/");
                }
            }
        }
    }
</script>
